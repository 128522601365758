import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "../../../../ui-treact/components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "../../../../ui-treact/components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "../../../../ui-treact/components/misc/Buttons.js";
// import ArrowRightIcon from "../svgs/ArrowRightIcon.js";
import TimeIcon from "../svgs/TimeIcon.js";
import LocationIcon from "../svgs/LocationIcon.js";
import ScrollAnimation from 'react-animate-on-scroll';
import "src/theme/styles/animate.min.css"; 
import BH from 'src/images/bh.png'
import MKTG from 'src/images/mktg.png'
// import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
// import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg"; 
// import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-icon.svg";
// const StyledDiv = styled.div``

const Container = tw.div`relative bg-white w-full`;
const Content = tw.div`mx-auto py-8`;

const ThreeColumn = tw.div`flex flex-wrap flex-col md:flex-row`;
const Column = tw.div`flex items-stretch justify-center`;
const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-5/12`,
  props.text_on_left ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-20`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-1/4 mt-16 xl:mt-0 xl:last:ml-auto flex-1 xl:ml-20 mx-2`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-800 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto xl:mx-4 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.img_src}");`,
  tw`h-56 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-primary-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h5`text-base mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-start sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
// const CardAction = tw(PrimaryButtonBase)`w-full mt-6`;

export default ({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
        sub_heading: "Our Portfolio",
        headingHtmlComponent: (
          <>
            We've done some <span tw="text-primary-500">amazing projects.</span>
          </>
        ),
        description: "We are dedicated to creating uniquely your services programs and building a long-lasting relationship. We’re a full-service firm with a passion for a radical open mind and radical transparency. No surprises, no gimmicks, just the bottom line.",
        link_text: "View all Projects",
        card_link_text: "Read Case Study",
        text_on_left: false,
        cards:[
            {
                img_src:BH,
                company: "BRANDHOUSE DNA.",
                type: "We Build Success",
                title: "SEO, Email Marketing, Pagespeed, Data & Analytics, Custom Carts, Digital Design, A/B Testing",
                duration_text: "24/7",
                location_text: "New York"
              },
              {
                img_src:MKTG,
                company: "MarketingDNA",
                type: "Marketing Services",
                title: "We’re full service which means we’ve got you covered from start to finish for all your marketing services.",
                duration_text: "24/7",
                location_text: "New York"
              }
        ],
      //  ...finalData
  } 


  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn text_on_left={content.text_on_left}>
            <HeadingInfoContainer>
              <Subheading>{content.sub_heading}</Subheading>
              <HeadingTitle>{content.headingHtmlComponent}</HeadingTitle>
              <HeadingDescription>{content.description}</HeadingDescription>
              {/* <PrimaryLink>
                {content.link_text} <ArrowRightIcon />
              </PrimaryLink> */}
            </HeadingInfoContainer>
          </HeadingColumn>
          {content.cards.map((card, index) => (
            <CardColumn key={index}>
            <ScrollAnimation animateIn='fadeInUp' delay={index*150} animateOnce={true}>
              <Card>
                <CardImage img_src={card.img_src} />
                <CardText>
                  <CardHeader>
                    <CardCompany>{card.company}</CardCompany>
                    <CardType>{card.type}</CardType>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  <CardMeta>
                    <CardMetaFeature>
                      <TimeIcon /> {card.duration_text}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <LocationIcon /> {card.location_text}
                    </CardMetaFeature>
                  </CardMeta>
                  {/* <CardAction>{content.card_link_text}</CardAction> */}
                </CardText>
              </Card>
            </ScrollAnimation>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
